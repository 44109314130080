import {
  ColStyled,
  ContainerStyled,
  Download,
  RowStyled,
  Section,
} from "./Banner.Styled";
import { blueGrey } from "@material-ui/core/colors";
import Adroin from "assets/svg/Adroin.svg";
import IOS from "assets/svg/IOS.svg";
import bg from "assets/svg/bg-Trusted.svg";
import Bg from "assets/svg/bg-countdown.svg";

function BannerCountDown() {
  return (
    <>
      <Section>
        <ContainerStyled>
          <RowStyled style={{ alignItems: "center" }}>
            <ColStyled md={12} lg={6} data-aos="fade-up" data-aos-offset="30">
              <Download.main>
                <Download.wrapper>
                  <h1 data-aos="zoom-in-up" data-aos-offset="30">
                    ToEarn <strong> NOW </strong> <br />{" "}
                  </h1>
                  <div data-aos="zoom-in-up" data-aos-offset="30">
                    {/* <p>Download NOW Wallet</p> */}
                    <div className="flex items-center justify-center gap-3 flex-wrap mt-4">
                      <a href="https://testflight.apple.com/join/UIdBgJp1">
                        <img src={IOS} alt="" />
                      </a>
                      <a href="https://play.google.com/store/apps/details?id=com.toearnnow.app">
                        <img src={Adroin} alt="" />
                      </a>
                    </div>
                  </div>
                  <br />
                  <h1 data-aos="zoom-in-up" data-aos-offset="30">
                    <strong> NOW </strong>Crypto Wallet{" "}
                    <span style={{ fontSize: "12px", color: blueGrey }}>
                      Beta
                    </span>
                    <br />{" "}
                  </h1>

                  <div
                    className="flex items-center justify-center gap-3 flex-wrap mt-4"
                    data-aos="zoom-in-up"
                    data-aos-offset="30"
                  >
                    <a
                      href="https://testflight.apple.com/join/ZlOZKbWY"
                      target="_blank"
                    >
                      <img src={IOS} alt="" />
                    </a>

                    <a
                      href="https://play.google.com/store/apps/details?id=com.toearnnow.nowwallet"
                      target="_blank"
                    >
                      <img src={Adroin} alt="" />
                    </a>
                  </div>
                </Download.wrapper>
              </Download.main>
            </ColStyled>

            <ColStyled
              md={12}
              lg={6}
              data-aos="zoom-in-up"
              data-aos-offset="30"
            >
              <img src={Bg} alt="" />
            </ColStyled>
          </RowStyled>
        </ContainerStyled>
      </Section>
      <img
        src={bg}
        alt=""
        style={{
          position: "absolute",
          bottom: "0",
          width: "80%",
          right: "-45%",
          zIndex: "-1",
        }}
      />
    </>
  );
}

export default BannerCountDown;
