import { Address, ListImage, PhoneInput, Price, Wrapper } from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress } from "@mui/material";
import { fixedNumber, formatMoneyDot } from "components/FormatNumber";
import {
  ErrorMessage,
  MessageToastError,
  swalWithBootstrapButtons,
} from "components/Message";
import Recaptcha from "components/ReCaptcha/Recaptcha";
import RecaptchaF2A from "components/RecaptchaF2A/RecaptchaF2A";
import SVG from "components/SVG";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { TagsInput } from "react-tag-input-component";
import { Overlay } from "redux/slices/auth";
import {
  handleGetListCategory,
  handleGetListCountry,
  handleGetListProvince,
  handleUploadFile,
} from "redux/slices/maps";

const FormCreateLocation = ({ position }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const search = useLocation().search;
  const lat = new URLSearchParams(search).get("lat");
  const lng = new URLSearchParams(search).get("lng");
  const address = new URLSearchParams(search).get("address");
  const { getPriceNow } = useSelector((state) => state.wallet);

  const { gettokenrep } = useSelector((state) => state.recaptchaF2A);
  const { getLoadding } = useSelector((state) => state.maps);
  const { blockNow } = useSelector((state) => state.ico);

  const [features, setFeatures] = useState(["WIFI"]);
  const [arrImg, setArrImg] = useState([]);
  const [check2fa, setCheck2fa] = useState(false);
  const [payload, setPayload] = useState({
    name: "",
    address: address,
    description: "",
    email: "",
    phone: "",
  });
  const [country, setCountry] = useState();
  const [province, setProvince] = useState();
  const [category, setCategory] = useState();
  const [img, setImg] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState({});

  const { listCategory, listCountry, listProvince } = useSelector(
    (state) => state.maps
  );

  const optionsCate =
    listCategory &&
    listCategory?.map((item) => {
      return { value: item.id, label: item.name };
    });

  const optionsCountry =
    listCountry &&
    listCountry?.map((item) => {
      return {
        value: item.iso2,
        label: item.name,
        id: item.id,
        code: item.code,
      };
    });

  const optionsProvince =
    listProvince &&
    listProvince?.map((item) => {
      return { value: item.iso2, label: item.name, id: item.id };
    });

  const handleChangeImage = (e) => {
    const { files } = e.target;
    if (files?.length > 3) {
      MessageToastError("Only a maximum of 3 images can be selected.");
    } else {
      dispatch(handleUploadFile({ file: files[0] }))
        .then((result) => {
          if (result) {
            setImg((prevImg) => [...prevImg, result?.url]);
            setArrImg([...arrImg, ...files]);
          }
        })
        .catch((error) => {});
    }
  };

  const removeImage = (imgs, index) => {
    let arr = arrImg;
    arr.splice(arr.indexOf(imgs), 1);
    setArrImg([...arr]);
    let newImg = [...img];
    newImg.splice(index, 1);
    setImg(newImg);
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    let newValue = value;
    if (name === "phone") {
      if (value.match(/[^0-9]/) || value === "e") {
        return;
      }
      if (value.startsWith("0")) {
        newValue = value.slice(1);
      }
    }
    setPayload({
      ...payload,
      [name]: newValue,
    });
  };

  useEffect(() => {
    dispatch(handleGetListProvince(country));
    setSelectedProvince([]);
  }, [country]);

  useEffect(() => {
    dispatch(handleGetListCategory());
    dispatch(handleGetListCountry());
  }, []);

  const handleSubmit = () => {
    if (!gettokenrep) {
      ErrorMessage("Invalid Recaptcha");
    } else {
      if (
        payload.name &&
        payload.address &&
        payload.description &&
        features &&
        lat &&
        lng &&
        country.id &&
        province.id &&
        category.value
      ) {
        const data = {
          name: payload.name,
          address: payload.address,
          email: payload.email,
          phone: `+${country?.code}${payload.phone}`,
          description: payload.description,
          images: img,
          features: features,
          latitude: lat,
          longitude: lng,
          nation: country.id,
          city: province.id,
          place: category.value,
        };

        swalWithBootstrapButtons(dispatch, Overlay, data, navigate);
      } else {
        ErrorMessage("Please enter input");
      }
    }
  };
  if (!lat || !lng) return <Navigate to="/maps" />;
  return (
    <>
      {" "}
      <Wrapper.Main>
        <Wrapper.Title>Buy a new location NFT</Wrapper.Title>
        <Price>
          <p>
            {" "}
            <span>Lat: </span> {lat}
          </p>
          <p>
            <span>Lng: </span>
            {lng}
          </p>
        </Price>
        <Wrapper.Des>
          NFT are unique in terms of their traits, making them perfect for
          representing rare items such as real properties. It cannot be divided,
          duplicated, or increased in quantity. There can only be limited amount
          of each type. Thus, the value of each NFT to be increased for the
          following timeline and maximum quantity sold whichever comes first.
        </Wrapper.Des>
        <div></div>

        <Wrapper.Context>
          <Address>
            <input
              type="text"
              name="name"
              placeholder="Business name*"
              onChange={handleChange}
            />
            <div style={{ width: "100%" }}>
              <Select
                options={optionsCate}
                placeholder="Category*"
                onChange={(value) => setCategory(value)}
                isClearable={true}
              />
            </div>
          </Address>

          <Address>
            <div style={{ width: "100%" }}>
              <Select
                options={optionsCountry}
                placeholder="Country*"
                onChange={(value) => setCountry(value)}
                isClearable={true}
              />
            </div>
            <div style={{ width: "100%" }}>
              <Select
                autosize={true}
                value={selectedProvince}
                options={optionsProvince}
                placeholder="Province or State*"
                onChange={(value) => {
                  setProvince(value);
                  setSelectedProvince(value);
                }}
                isClearable={true}
              />
            </div>
          </Address>
          <Address>
            <input
              type="text"
              defaultValue={address}
              name="address"
              placeholder="Address*"
              onChange={handleChange}
            />

            <div style={{ width: "100%" }}>
              <TagsInput
                value={features}
                onChange={setFeatures}
                name="features"
                placeHolder="Wifi free, Street parking…*"
              />
            </div>
          </Address>
          <Address>
            <input
              type="email"
              name="email"
              placeholder="Email (optional)"
              onChange={handleChange}
            />
            <PhoneInput.Wrapper>
              <PhoneInput.Code>
                {country && `+${country?.code}`}
              </PhoneInput.Code>
              <PhoneInput.Input
                type="tel"
                name="phone"
                placeholder="Phone (optional)"
                onChange={handleChange}
                value={payload.phone || ""}
                maxLength={15}
              />
            </PhoneInput.Wrapper>
          </Address>
          <textarea
            name="description"
            id=""
            cols="30"
            rows="5"
            maxLength={2000}
            placeholder="Location description, no more than 2000 characters…*"
            onChange={handleChange}
          />

          <ListImage.Main>
            {arrImg &&
              arrImg?.map((item, index) => (
                <ListImage.Item key={index}>
                  <img src={URL.createObjectURL(item)} alt="Upload images" />
                  <CloseIcon onClick={() => removeImage(item, index)} />
                </ListImage.Item>
              ))}

            {arrImg.length <= 2 && (
              <ListImage.Item>
                <div style={{ width: "50%" }}>
                  <SVG name="kyc" />
                </div>
                <input
                  type="file"
                  name="images"
                  // multiple
                  accept="image/png, image/jpeg"
                  onChange={handleChangeImage}
                />
              </ListImage.Item>
            )}
          </ListImage.Main>

          {!check2fa ? <Recaptcha /> : <RecaptchaF2A />}
          <Price style={{ width: "fit-content" }}>
            <p>
              {" "}
              1 Location NFT ={" "}
              <span>
                {" "}
                {formatMoneyDot(
                  String(
                    fixedNumber(blockNow?.price / getPriceNow?.set_price, 0)
                  )
                )}{" "}
              </span>{" "}
              NOW
            </p>
          </Price>
          <Wrapper.Submit onClick={handleSubmit}>
            {" "}
            {getLoadding ? (
              <div style={{ width: "100%", textAlign: "center" }}>
                <CircularProgress />{" "}
              </div>
            ) : (
              "Buy Location NFT"
            )}
          </Wrapper.Submit>
        </Wrapper.Context>
      </Wrapper.Main>
    </>
  );
};
export default FormCreateLocation;
