import { DifferentMethods } from "./DifferentMethods";
import { GetADemo } from "./GetADemo";
import { Helping } from "./Helping";
import { JoinUs } from "./JoinUs";
import { ManageNow } from "./ManageNow";
import { NowMarketingSection } from "./NowMarketing";
import { OurClients } from "./OurClients";
import { Security } from "./Security";
import Layer3 from "assets/images/layer-three.png";
import Layer2 from "assets/images/layer-two.png";
import Layer from "assets/images/layer.png";
import BackgroundAnimation from "components/BackgroundAnimation";

const { default: Banner } = require("./Banner");

const HomeContainer = () => {
  return (
    <div>
      <Banner />
      <div
        className="bg-cover bg-center bg-no-repeat relative"
        style={{
          backgroundImage: `url(${Layer})`,
        }}
      >
        <OurClients />
        <BackgroundAnimation />
        <ManageNow />
        <Security />
      </div>
      <BackgroundAnimation />

      <div
        className="bg-cover bg-top bg-no-repeat relative"
        style={{
          backgroundImage: `url(${Layer2})`,
        }}
      >
        <Helping />
        <DifferentMethods />
        <BackgroundAnimation />
      </div>
      <div
        className="bg-cover bg-top bg-no-repeat relative"
        style={{
          backgroundImage: `url(${Layer3})`,
        }}
      >
        <JoinUs />
        <BackgroundAnimation />

        <NowMarketingSection />
      </div>
      <GetADemo />
    </div>
  );
};

export default HomeContainer;
