import now1 from "assets/images/home/manage-now1.svg";
import now2 from "assets/images/home/manage-now2.svg";
import now3 from "assets/images/home/manage-now3.svg";
import { EffectCoverflow, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export const ManageNow = () => {
  return (
    <div className="py-10 md:py-16 container flex items-center gap-8 flex-col">
      <p
        data-aos="fade-up"
        className="md:text-xl text-center text-base text-white font-extrabold container sm:max-w-none md:max-w-none lg:container mx-auto"
      >
        Our Values
      </p>
      <p
        data-aos="fade-up"
        className="text-center text-white w-[650px] mx-auto md:text-4xl text-2xl font-extrabold -pt-3"
      >
        How We Conduct Business
      </p>
      <div className="hidden lg:grid  grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-10">
        <div
          data-aos="fade-right"
          className="bg-cover bg-center bg-no-repeat relative overflow-hidden rounded-xl shadow-lg pt-6 bg-black md:bg-transparent"
          style={{
            backgroundImage: 'url("../../assets/images/home/manage-now.png")',
          }}
        >
          <div className="flex items-center justify-center gap-5 flex-col py-3">
            <img
              src={now1}
              width={100}
              height={100}
              className="min-h-[86px]"
              alt={`Mamage now 1`}
            />
            <p className="text-3xl  font-bold text-center text-primary-4 max-w-[250px] min-h-[75px]">
              Innovation
            </p>
          </div>
          <div className="bg-gradient-manage-now h-full rounded-2xl px-5 py-8">
            <p className="text-base font-medium text-center text-white">
              We channel our efforts into driving tangible outcomes for our
              clients, dedicated to propelling them toward success and realizing
              their goals
            </p>
          </div>
        </div>
        <div
          data-aos="fade-right"
          className="bg-cover bg-center bg-no-repeat relative overflow-hidden rounded-xl shadow-lg pt-6 bg-black md:bg-transparent"
          style={{
            backgroundImage: 'url("../../assets/images/home/manage-now.png")',
          }}
        >
          <div className="flex items-center justify-center gap-5 flex-col py-3">
            <img
              src={now2}
              width={100}
              height={100}
              className="min-h-[86px]"
              alt={`Mamage now 2`}
            />
            <p className="text-3xl font-bold text-center text-primary-4 max-w-[250px] min-h-[75px]">
              Collaboration
            </p>
          </div>
          <div className="bg-gradient-manage-now h-full rounded-2xl px-5 py-8">
            <p className="text-base font-medium text-center text-white">
              We prioritize collaboration to ensure our clients not only attain
              their goals but also witness tangible results through our
              dedicated efforts
            </p>
          </div>
        </div>
        <div
          data-aos="fade-right"
          className="bg-cover bg-center bg-no-repeat relative overflow-hidden rounded-xl shadow-lg pt-6 bg-black md:bg-transparent mx-auto md:mx-none"
          style={{
            backgroundImage: 'url("../../assets/images/home/manage-now.png")',
          }}
        >
          <div className="flex items-center justify-center gap-5 flex-col py-3">
            <img
              src={now3}
              width={100}
              height={100}
              className="min-h-[86px]"
              alt={`Mamage now 3`}
            />
            <p className="text-3xl font-bold text-center text-primary-4 max-w-[250px] min-h-[75px]">
              Transparency
            </p>
          </div>
          <div className="bg-gradient-manage-now h-full rounded-2xl px-5 py-8">
            <p className="text-base font-medium text-center text-white">
              We prioritize transparency in our commitment to delivering results
              for our clients and helping them in accomplishing their objectives
            </p>
          </div>
        </div>
        <div
          data-aos="fade-right"
          className="bg-cover bg-center bg-no-repeat relative overflow-hidden rounded-xl shadow-lg pt-6 bg-black md:bg-transparent"
          style={{
            backgroundImage: 'url("../../assets/images/home/manage-now.png")',
          }}
        >
          <div className="flex items-center justify-center gap-5 flex-col py-3">
            <img
              src={now1}
              width={100}
              height={100}
              className="min-h-[86px]"
              alt={`Mamage now 1`}
            />
            <p className="text-3xl font-bold text-center text-primary-4 max-w-[250px] min-h-[75px]">
              Result-Driven
            </p>
          </div>
          <div className="bg-gradient-manage-now h-full rounded-2xl px-5 py-8">
            <p className="text-base font-medium text-center text-white">
              We are dedicated to achieving tangible outcomes for our clients,
              actively working towards assisting them in reaching their goals
            </p>
          </div>
        </div>
      </div>
      <div className="w-full overflow-hidden relative block lg:hidden">
        <Swiper
          effect={"coverflow"}
          centeredSlides={true}
          coverflowEffect={{
            rotate: 7,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          }}
          loop={false}
          pagination={true}
          modules={[EffectCoverflow, Pagination]}
          breakpoints={{
            300: {
              slidesPerView: 2,
            },
          }}
          initialSlide={2}
          spaceBetween={20}
          slidesPerView={3}
        >
          <SwiperSlide>
            <div
              className="bg-cover bg-center bg-no-repeat relative overflow-hidden rounded-xl shadow-lg pt-6 bg-black md:bg-transparent w-[300px]"
              style={{
                backgroundImage:
                  'url("../../assets/images/home/manage-now.png")',
              }}
            >
              <div className="flex items-center justify-center gap-5 flex-col py-3">
                <img
                  src={now1}
                  width={100}
                  height={100}
                  className="min-h-[86px]"
                  alt={`Mamage now 1`}
                />
                <p className="text-3xl font-bold text-center text-primary-4 max-w-[250px] min-h-[75px]">
                  Innovation
                </p>
              </div>
              <div className="bg-gradient-manage-now h-full rounded-2xl px-5 py-8">
                <p className="text-base font-medium text-center text-white">
                  We channel our efforts into driving tangible outcomes for our
                  clients, dedicated to propelling them toward success and
                  realizing their goals
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="bg-cover bg-center bg-no-repeat relative overflow-hidden rounded-xl shadow-lg pt-6 bg-black md:bg-transparent w-[300px]"
              style={{
                backgroundImage:
                  'url("../../assets/images/home/manage-now.png")',
              }}
            >
              <div className="flex items-center justify-center gap-5 flex-col py-3">
                <img
                  src={now2}
                  width={100}
                  height={100}
                  className="min-h-[86px]"
                  alt={`Mamage now 2`}
                />
                <p className="text-3xl font-bold text-center text-primary-4 max-w-[250px] min-h-[75px]">
                  Collaboration
                </p>
              </div>
              <div className="bg-gradient-manage-now h-full min-h-[200px] rounded-2xl px-5 py-8">
                <p className="text-base font-medium text-center text-white">
                  We prioritize collaboration to ensure our clients not only
                  attain their goals but also witness tangible results through
                  our dedicated efforts
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="bg-cover bg-center bg-no-repeat relative overflow-hidden rounded-xl shadow-lg pt-6 bg-black md:bg-transparent w-[300px]"
              style={{
                backgroundImage:
                  'url("../../assets/images/home/manage-now.png")',
              }}
            >
              <div className="flex items-center justify-center gap-5 flex-col py-3">
                <img
                  src={now3}
                  width={100}
                  height={100}
                  className="min-h-[86px]"
                  alt={`Mamage now 3`}
                />
                <p className="text-3xl font-bold text-center text-primary-4 max-w-[250px] min-h-[75px]">
                  Transparency
                </p>
              </div>
              <div className="bg-gradient-manage-now h-full min-h-[200px] rounded-2xl px-5 py-8">
                <p className="text-base font-medium text-center text-white">
                  We prioritize transparency in our commitment to delivering
                  results for our clients and helping them in accomplishing
                  their objectives
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="bg-cover bg-center bg-no-repeat relative overflow-hidden rounded-xl shadow-lg pt-6 bg-black md:bg-transparent w-[300px]"
              style={{
                backgroundImage:
                  'url("../../assets/images/home/manage-now.png")',
              }}
            >
              <div className="flex items-center justify-center gap-5 flex-col py-3">
                <img
                  src={now1}
                  width={100}
                  height={100}
                  className="min-h-[86px]"
                  alt={`Mamage now 1`}
                />
                <p className="text-3xl font-bold text-center text-primary-4 max-w-[250px] min-h-[75px]">
                  Result-Driven
                </p>
              </div>
              <div className="bg-gradient-manage-now h-full rounded-2xl px-5 py-8">
                <p className="text-base font-medium text-center text-white">
                  We are dedicated to achieving tangible outcomes for our
                  clients, actively working towards assisting them in reaching
                  their goals
                </p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};
