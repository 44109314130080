import { listFeatures } from "./Data";
import {
  Section,
  Content,
  Item,
  ContainerStyled,
  Text,
  Content2,
  TextItem,
  Des,
} from "./Trusted.Styled";
import Adroin from "assets/svg/Adroin.svg";
import IOS from "assets/svg/IOS.svg";
import bg2 from "assets/svg/bg-Trusted2.svg";
import bg from "assets/svg/bg-Trusted.svg";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MenuDrawer } from "redux/slices/menu";
import "swiper/css";
import "swiper/css/pagination";
import { EffectCoverflow, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { GetUser } from "utits/auth";

function Trusted() {
  const user = GetUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleRedirect = (e) => {
    if (!user) {
      navigate("/auth/login");
    } else {
      navigate(e?.link);
      dispatch(MenuDrawer(e?.menu));
    }
  };
  return (
    <Section>
      <ContainerStyled>
        <div data-aos="fade-up" data-aos-offset="30">
          <h1>
            {" "}
            FIND, LOVE, MINT <strong> NOW</strong>{" "}
          </h1>
        </div>
        <Content>
          {listFeatures?.map((e, i) => (
            <Item data-aos="zoom-in-up" data-aos-offset="30" key={i}>
              <div>
                <img src={e?.img} alt="" onClick={() => handleRedirect(e)} />
              </div>{" "}
              <Text>
                <TextItem> {e?.title} </TextItem> <Des> {e?.des} </Des>{" "}
              </Text>
            </Item>
          ))}
        </Content>

        <Content2>
          <Swiper
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
            style={{ overflow: "initial" }}
          >
            {listFeatures?.map((e, i) => (
              <SwiperSlide key={i}>
                <Item data-aos="zoom-in-up" data-aos-offset="30" key={i}>
                  <div>
                    <img src={e?.img} alt="" />
                  </div>{" "}
                  <Text>
                    <TextItem> {e?.title} </TextItem> <Des> {e?.des} </Des>{" "}
                  </Text>
                </Item>
              </SwiperSlide>
            ))}{" "}
          </Swiper>
        </Content2>
        <img src={bg} alt="" />
      </ContainerStyled>
    </Section>
  );
}

export default Trusted;
