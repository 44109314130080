import { data } from "./helper";
import { Block, Line } from "./styles";
import { Typography } from "@mui/material";
import bg from "assets/svg/bg-Trusted.svg";
import { fixedNumber, formatMoneyDot } from "components/FormatNumber";
import SVG from "components/SVG";
import _ from "lodash";
import CountUp from "react-countup";
import { useSelector } from "react-redux";

const Timeline = () => {
  const { blockNow } = useSelector((state) => state.ico);
  const { getPriceNow } = useSelector((state) => state.wallet);

  const block = blockNow?.name?.substring(blockNow?.name.length - 1);
  return (
    <>
      <Block.Container
      // style={{
      //   background: `url(${bg})`,
      //   backgroundRepeat: "no-repeat",
      //   backgroundSize: "cover",
      // }}
      >
        <Block.Main>
          <Block.Top>
            {data?.map((item, index) => {
              if (item?.id % 2 === 0) {
                return (
                  <Block.Item
                    className={item?.id == block ? "active" : ""}
                    key={index}
                  >
                    <img src={item?.icon} alt="" />
                    <Block.Price>
                      {formatMoneyDot(
                        String(
                          fixedNumber(item?.price / getPriceNow?.set_price, 0)
                        )
                      )}{" "}
                      <span style={{ fontWeight: "700" }}>NOW</span>
                    </Block.Price>

                    {/* <Block.Date>{item?.date}</Block.Date> */}
                    <Block.Total>MAX {item?.total}</Block.Total>
                  </Block.Item>
                );
              }
            })}
          </Block.Top>
          <Line.Main>
            <Line.Timeline></Line.Timeline>
            <Line.Icon>
              {_.times(7, (i) => (
                <SVG name="timeline"></SVG>
              ))}
            </Line.Icon>
          </Line.Main>

          <Block.Bottom>
            {data?.map((item, index) => {
              if (item?.id % 2 !== 0) {
                return (
                  <Block.Item
                    className={item?.id == block ? "active" : ""}
                    key={index}
                  >
                    <img src={item?.icon} alt="" />
                    <Block.Price>
                      {formatMoneyDot(
                        String(
                          fixedNumber(item?.price / getPriceNow?.set_price, 0)
                        )
                      )}{" "}
                      <span style={{ fontWeight: "700" }}>NOW</span>
                    </Block.Price>

                    {/* <Block.Date>{item?.date}</Block.Date> */}
                    <Block.Total>MAX {item?.total}</Block.Total>
                  </Block.Item>
                );
              }
            })}
          </Block.Bottom>
        </Block.Main>
      </Block.Container>
    </>
  );
};
export default Timeline;
