import marketing1 from "assets/images/home/marketing1.png";
import marketing2 from "assets/images/home/marketing2.png";
import marketing3 from "assets/images/home/marketing3.png";
import ArrowRightIcon from "icons/Arrow/ArrowRight";
import { EffectCoverflow, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export const NowMarketingSection = () => {
  return (
    <div className="py-10 md:py-16 container flex items-center gap-8 flex-col">
      <p
        data-aos="fade-up"
        className="md:text-5xl text-white text-center text-3xl font-extrabold container sm:max-w-none md:max-w-none lg:container mx-auto"
      >
        Caring is the new marketing
      </p>
      <p
        data-aos="fade-up"
        className="text-base text-white text-center w-full mx-auto md:text-xl max-w-3xl"
      >
        The Now Marketing blog is the best place to read about the latest
        membership insights, trends and more. See who's joining the community,
        read about how our community are increasing their membership income and
        lot's more.​
      </p>
      <div
        data-aos="fade-up"
        className="hidden lg:grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10"
      >
        <div className="relative overflow-hidden">
          <div className="flex items-center justify-center gap-5 flex-col">
            <img
              src={marketing1}
              className="w-full"
              alt="Now marketing"
              width={368}
              height={286}
            />
          </div>
          <div className="shadow-md bg-gradient-marketing -translate-y-[50%] w-[90%] mx-auto rounded-2xl px-5 py-8">
            <p className="text-xl font-bold text-center text-white">
              Creating Streamlined Safeguarding Processes with OneRen
            </p>
            <button className="flex items-center mx-auto justify-start gap-2 my-4 sm:my-0 min-w-max cursor-pointer relative font-bold duration-300 text-primary-4 px-4 py-2 md:px-6 md:py-3 text-base xl:text-xl hover:-translate-y-0.5 rounded-md">
              Read More
              <span className="w-5">
                <ArrowRightIcon />
              </span>
            </button>
          </div>
        </div>
        <div className="relative overflow-hidden">
          <div className="flex items-center justify-center gap-5 flex-col">
            <img
              src={marketing2}
              className="w-full"
              alt="Now marketing"
              width={368}
              height={286}
            />
          </div>
          <div className="shadow-md bg-gradient-marketing -translate-y-[50%] w-[90%] mx-auto rounded-2xl px-5 py-8">
            <p className="text-xl font-bold text-center text-white">
              What are your safeguarding responsibilities and how can you manage
              them?
            </p>
            <button className="flex items-center mx-auto justify-start gap-2 my-4 sm:my-0 min-w-max cursor-pointer relative font-bold duration-300 text-primary-4 px-4 py-2 md:px-6 md:py-3 text-base xl:text-xl hover:-translate-y-0.5 rounded-md">
              Read More
              <span className="w-5">
                <ArrowRightIcon />
              </span>
            </button>
          </div>
        </div>
        <div className="relative overflow-hidden">
          <div className="flex items-center justify-center gap-5 flex-col">
            <img
              src={marketing3}
              className="w-full"
              alt="Now marketing"
              width={368}
              height={286}
            />
          </div>
          <div className="shadow-md bg-gradient-marketing -translate-y-[50%] w-[90%] mx-auto rounded-2xl px-5 py-8">
            <p className="text-xl font-bold text-center text-white">
              Revamping the Membership Model with Now Marketing
            </p>
            <button className="flex items-center mx-auto justify-start gap-2 my-4 sm:my-0 min-w-max cursor-pointer relative font-bold duration-300 text-primary-4 px-4 py-2 md:px-6 md:py-3 text-base xl:text-xl hover:-translate-y-0.5 rounded-md">
              Read More
              <span className="w-5">
                <ArrowRightIcon />
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="w-full overflow-hidden relative block lg:hidden">
        <Swiper
          effect={"coverflow"}
          centeredSlides={true}
          coverflowEffect={{
            rotate: 7,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          }}
          loop={false}
          pagination={true}
          modules={[EffectCoverflow, Pagination]}
          breakpoints={{
            300: {
              slidesPerView: 2,
            },
          }}
          initialSlide={2}
          spaceBetween={20}
          slidesPerView={3}
        >
          <SwiperSlide>
            <div className="relative overflow-hidden w-[300px]">
              <div className="flex items-center justify-center gap-5 flex-col">
                <img
                  src={marketing1}
                  className="w-full"
                  alt="Now marketing"
                  width={368}
                  height={286}
                />
              </div>
              <div className="shadow-md bg-gradient-marketing -translate-y-[50%] w-[90%] mx-auto rounded-2xl px-5 py-8">
                <p className="text-xl font-bold text-center text-white">
                  Creating Streamlined Safeguarding Processes with OneRen
                </p>
                <button className="flex items-center mx-auto justify-start gap-2 my-4 sm:my-0 min-w-max cursor-pointer relative font-bold duration-300 text-primary-4 px-4 py-2 md:px-6 md:py-3 text-base xl:text-xl hover:-translate-y-0.5 rounded-md">
                  Read More
                  <span className="w-5">
                    <ArrowRightIcon />
                  </span>
                </button>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="relative overflow-hidden w-[300px]">
              <div className="flex items-center justify-center gap-5 flex-col">
                <img
                  src={marketing2}
                  className="w-full"
                  alt="Now marketing"
                  width={368}
                  height={286}
                />
              </div>
              <div className="shadow-md bg-gradient-marketing -translate-y-[50%] w-[90%] mx-auto rounded-2xl px-5 py-8">
                <p className="text-xl font-bold text-center text-white">
                  What are your safeguarding responsibilities and how can you
                  manage them?
                </p>
                <button className="flex items-center mx-auto justify-start gap-2 my-4 sm:my-0 min-w-max cursor-pointer relative font-bold duration-300 text-primary-4 px-4 py-2 md:px-6 md:py-3 text-base xl:text-xl hover:-translate-y-0.5 rounded-md">
                  Read More
                  <span className="w-5">
                    <ArrowRightIcon />
                  </span>
                </button>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="relative overflow-hidden w-[300px]">
              <div className="flex items-center justify-center gap-5 flex-col">
                <img
                  src={marketing3}
                  className="w-full"
                  alt="Now marketing"
                  width={368}
                  height={286}
                />
              </div>
              <div className="shadow-md bg-gradient-marketing -translate-y-[50%] w-[90%] mx-auto rounded-2xl px-5 py-8">
                <p className="text-xl font-bold text-center text-white">
                  Revamping the Membership Model with Now Marketing
                </p>
                <button className="flex items-center mx-auto justify-start gap-2 my-4 sm:my-0 min-w-max cursor-pointer relative font-bold duration-300 text-primary-4 px-4 py-2 md:px-6 md:py-3 text-base xl:text-xl hover:-translate-y-0.5 rounded-md">
                  Read More
                  <span className="w-5">
                    <ArrowRightIcon />
                  </span>
                </button>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};
