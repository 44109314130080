const FacebookIcon = (props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${props.color}`}
    >
      <path
        d="M31.3767 16.3344C31.3767 7.68508 24.3555 0.668457 15.6883 0.668457C7.0212 0.668457 0 7.67962 0 16.3344C0 24.1537 5.73617 30.6352 13.2386 31.8092V20.861H9.25771V16.3344H13.2386V12.8834C13.2386 8.95735 15.579 6.78957 19.1661 6.78957C20.8832 6.78957 22.6767 7.09536 22.6767 7.09536V10.9504H20.6972C18.7506 10.9504 18.1381 12.1572 18.1381 13.3967V16.3344H22.4908L21.7964 20.861H18.1436V31.8092C25.6405 30.6297 31.3767 24.1537 31.3767 16.3344Z"
        fill="#FFF"
      />
      <path
        d="M21.7964 20.861L22.4908 16.3343H18.1381V13.3966C18.1381 12.1571 18.7451 10.9504 20.6972 10.9504H22.6767V7.09534C22.6767 7.09534 20.8832 6.78955 19.1661 6.78955C15.5844 6.78955 13.2386 8.95733 13.2386 12.8834V16.3343H9.25771V20.861H13.2386V31.8091C14.0369 31.9347 14.8572 32.0002 15.6883 32.0002C16.5195 32.0002 17.3397 31.9347 18.1381 31.8091V20.861H21.7964Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default FacebookIcon;
