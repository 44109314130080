import styled from "styled-components";

export const Block = {
  Container: styled.div`
    overflow: scroll;
  `,
  Main: styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    min-width: 1060px;
    overflow: scroll;
    padding: 90px 0;

    > div .active {
      background: linear-gradient(
        115.36deg,
        rgba(80, 45, 159, 0.8) 33.92%,
        rgba(8, 2, 28, 0) 99.86%
      );
      border: 8px solid #ffffff;
      filter: drop-shadow(0px 0px 10px #faa51a);
      border-radius: 186px;
      width: 173.15px;
      height: 173.15px;
    }
  `,
  Top: styled.div`
    display: flex;
    justify-content: space-around;
    padding: 0 70px;
  `,
  Bottom: styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 17px;
  `,

  Item: styled.div`
    width: 160px;
    height: 160px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
      115.36deg,
      rgb(22 10 51) 33.92%,
      rgb(0 0 0 / 55%) 99.86%
    );
    box-shadow: 0px 10px 28px rgb(0 0 0 / 12%);
  `,
  Price: styled.p`
    color: white;
    font-size: 18px;
    font-weight: bold;
    padding-top: 5px;
  `,
  Date: styled.p`
    color: #b9abab;
    font-size: 12px;
    font-weight: 400;
  `,
  Total: styled.p`
    color: #b9abab;
    font-size: 12px;
    font-weight: 400;
  `,
};

export const Line = {
  Main: styled.div`
    position: relative;
  `,
  Timeline: styled.div`
    position: absolute;
    top: 8px;
    width: 100%;
    height: 2px;
    background: aliceblue;
  `,
  Icon: styled.div`
    display: flex;
    justify-content: space-around;
  `,
};
