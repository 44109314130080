const YoutubeIcon = (props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${props.color}`}
    >
      <path
        d="M16.1544 31.3318C24.8188 31.3318 31.8427 24.3179 31.8427 15.6659C31.8427 7.01387 24.8188 0 16.1544 0C7.48993 0 0.466019 7.01387 0.466019 15.6659C0.466019 24.3179 7.48993 31.3318 16.1544 31.3318Z"
        fill="#FFF"
      />
      <path
        d="M26.1776 10.6369C25.937 9.73597 25.2261 9.03158 24.3294 8.79132C22.6998 8.35449 16.1598 8.35449 16.1598 8.35449C16.1598 8.35449 9.61983 8.35449 7.9903 8.79132C7.08804 9.03158 6.38264 9.74144 6.14203 10.6369C5.70458 12.2641 5.70457 15.666 5.70457 15.666C5.70457 15.666 5.70458 19.0624 6.14203 20.695C6.38264 21.596 7.09351 22.3004 7.9903 22.5406C9.61983 22.9775 16.1598 22.9775 16.1598 22.9775C16.1598 22.9775 22.6998 22.9775 24.3294 22.5406C25.2316 22.3004 25.937 21.5905 26.1776 20.695C26.6151 19.0678 26.6151 15.666 26.6151 15.666C26.6151 15.666 26.6096 12.2641 26.1776 10.6369ZM14.06 18.8003V12.5317L19.4954 15.666L14.06 18.8003Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default YoutubeIcon;
