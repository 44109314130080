import { CARD_DUMMY } from "../OurClients/helper";
import Logo5 from "assets/images/home/join-us.png";
import ArrowRightIcon from "icons/Arrow/ArrowRight";

export const JoinUs = () => {
  return (
    <div className="bg-gradient-helping">
      <div className="py-16 md:py-24 container overflow-hidden flex flex-col md:flex-row items-center justify-around gap-10 md:gap-5 lg:gap-8">
        <div
          data-aos="fade-down"
          className="flex w-[60%] md:w-[40%] xl:w-1/2 items-center"
        >
          <img src={Logo5} width={484} height={316} alt={`join-us`} />
        </div>
        <div
          data-aos="fade-down"
          className="flex flex-col gap-5 items-start justify-start w-full md:w-1/2"
        >
          <p className="text-base font-medium text-white">
            Join the Now Marketing project to discover the opportunity to reach
            millions of potential customers! Join us, you will experience
            advanced technology and effective marketing strategy. Accompanying
            to build breakthrough
          </p>
          <p className="text-4xl font-semibold text-white">Join</p>
          <p className="text-base  text-white">Members participated</p>
          <div className="flex  flex-col xl:flex-row  items-start xl:items-center justify-between gap-5">
            <div className="flex flex-row gap-5 items-center">
              {CARD_DUMMY.slice(8)?.map((item, index) => (
                <div key={index} className="pr-3">
                  <img
                    src={item?.img}
                    height={50}
                    width={50}
                    alt={`Members participated ${index + 1}`}
                  />
                </div>
              ))}
            </div>
            <button className="flex items-center justify-start gap-2 my-4 sm:my-0 min-w-max cursor-pointer relative font-bold duration-300 text-primary-4 text-white px-4 py-2 md:px-6 md:py-3 text-sm xl:text-base hover:-translate-y-0.5 bg-primary-4 rounded-md">
              Meet all customers
              <span className="w-6">
                <ArrowRightIcon />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
