import { communities, navFooter } from "./helper";
import applePlay from "assets/images/appStore.svg";
import googlePlay from "assets/images/googlePlay.svg";

const Footer = (props) => {
  return (
    <footer className="relative z-10 bg-black-2 text-primary-3">
      <div className="flex items-start container lg:flex-row flex-col gap-10 lg:gap-20 justify-between py-5 md:py-6 lg:pt-10">
        <div className="flex flex-col gap-6 items-center py-5 sm:items-start w-full justify-center sm:justify-start max-w-md">
          <a href={"/"}>
            <img src="/logo.svg" width={300} height={60} alt={`Logo`} />
          </a>
          <div className="flex items-center justify-start gap-5">
            <a href={"/"} target="_blank">
              <img src={applePlay} width={170} height={50} alt={`appStore`} />
            </a>
            <a href={"/"} target="_blank">
              <img
                src={googlePlay}
                width={170}
                height={50}
                alt={`googlePlay`}
              />
            </a>
          </div>
        </div>
        <div className="md:flex flex-1 items-start justify-between grid grid-cols-2 sm:grid-cols-2 md:gap-y-4 gap-y-0 w-full lg:w-auto xl:max-w-2xl">
          {navFooter?.map((category) => (
            <div className="col-span-1" key={category?.title}>
              <div className="py-1 text-lg text-white font-extrabold lg:h-8">
                {category?.title}
              </div>
              {category?.child?.map((child, index) => (
                <a
                  key={index}
                  href={child?.link}
                  target={`${index == 0 || index == 1 ? "_blank" : ""}`}
                  rel="noopener noreferrer"
                  className="mt-3 text-base font-normal duration-300 block hover:text-primary-4 text-white"
                >
                  {child?.name}
                </a>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className="container flex justify-between items-center py-8 md:flex-row flex-col gap-5">
        <div className="text-center">
          CopyRight © 2024 Now Marketing® All rights reserved.
        </div>
        <div className="flex gap-x-3">
          {communities?.map((child, index) => (
            <a key={index} target="_blank" className="cursor-pointer">
              {child?.icon}
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
};
export default Footer;
