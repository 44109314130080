import Logo2 from "assets/images/home/Logo2.svg";
import Logo3 from "assets/images/home/Logo3.svg";
import Logo4 from "assets/images/home/Logo4.svg";
import Logo5 from "assets/images/home/Logo5.svg";
import Logo6 from "assets/images/home/Logo6.svg";
import Logo7 from "assets/images/home/Logo7.svg";
import Logo from "assets/images/home/Logo.svg";

export const CARD_DUMMY = [
  {
    img: Logo,
  },
  {
    img: Logo2,
  },
  {
    img: Logo3,
  },
  {
    img: Logo4,
  },
  {
    img: Logo5,
  },
  {
    img: Logo6,
  },
  {
    img: Logo7,
  },
  {
    img: Logo,
  },
  {
    img: Logo2,
  },
  {
    img: Logo3,
  },
  {
    img: Logo4,
  },
  {
    img: Logo5,
  },
  {
    img: Logo6,
  },
  {
    img: Logo7,
  },
];
