import { CARD_DUMMY } from "./helper";
import Marquee from "react-fast-marquee";

export const OurClients = () => {
  return (
    <section className="py-10 md:py-16">
      <div className="text-center text-white flex flex-col gap-7">
        <p className="slider-title md:text-4xl text-2xl pb-3 font-extrabold container sm:max-w-none md:max-w-none lg:container mx-auto">
          Grow Your Business with NOW Digital Marketing
        </p>

        <Marquee>
          {CARD_DUMMY?.map((item, index) => (
            <div key={index} className="pr-3 w-3/4 sm:w-full md:pr-20">
              <img
                src={item?.img}
                height={100}
                width={100}
                alt={`Our Clients`}
              />
            </div>
          ))}
        </Marquee>
      </div>
    </section>
  );
};
