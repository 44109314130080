const TwitterIcon = (props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${props.color}`}
    >
      <path
        d="M15.6883 31.3318C24.3528 31.3318 31.3767 24.3179 31.3767 15.6659C31.3767 7.01387 24.3528 0 15.6883 0C7.02391 0 0 7.01387 0 15.6659C0 24.3179 7.02391 31.3318 15.6883 31.3318Z"
        fill="#FFF"
      />
      <path
        d="M13.0855 18.1284L12.73 21.9179C12.73 21.9179 12.5824 23.081 13.7417 21.9179C14.9064 20.7549 16.0164 19.8594 16.0164 19.8594L13.0855 18.1284Z"
        fill="currentColor"
      />
      <path
        d="M10.6521 16.9927L6.82435 15.7478C6.82435 15.7478 6.36502 15.5621 6.51266 15.1417C6.54547 15.0543 6.60562 14.9833 6.78607 14.8523C7.63365 14.2625 22.4854 8.93317 22.4854 8.93317C22.4854 8.93317 22.9064 8.7912 23.1525 8.88403C23.2673 8.92771 23.3384 8.97685 23.3986 9.15159C23.4204 9.21711 23.4314 9.35362 23.4314 9.49013C23.4314 9.58842 23.4204 9.68125 23.4095 9.82322C23.3165 11.2811 20.6098 22.1583 20.6098 22.1583C20.6098 22.1583 20.4457 22.7971 19.8661 22.819C19.6528 22.8244 19.3958 22.7862 19.0896 22.5187C17.9522 21.5413 14.0151 18.8984 13.1456 18.3196C13.0964 18.2868 13.08 18.2432 13.0745 18.2049C13.0636 18.1449 13.1292 18.0684 13.1292 18.0684C13.1292 18.0684 19.9809 11.9855 20.1668 11.3467C20.1832 11.2975 20.1286 11.2702 20.0575 11.2921C19.6036 11.4613 11.7129 16.4358 10.838 16.9873C10.7943 17.0146 10.6521 16.9927 10.6521 16.9927Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default TwitterIcon;
