import img from "assets/images/home/different-methods.png";

export const DifferentMethods = () => {
  return (
    <div className="py-10 md:py-16 container flex flex-col md:flex-row items-center gap-3 md:gap-8">
      <div
        data-aos="fade-right"
        className="flex flex-col items-start justify-start gap-3 md:gap-8 w-full md:w-1/2"
      >
        <p className="md:text-4xl text-left text-white text-2xl font-extrabold">
          Boosting Sales Through Our Marketing Expertise
        </p>
        <div className="flex flex-col gap-3">
          <div className="flex items-center justify-start gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="14"
              viewBox="0 0 18 14"
              fill="none"
            >
              <path
                d="M2 5.70588L7.18519 11L16 2"
                stroke="#F40074"
                strokeWidth="4"
                strokeLinecap="round"
              />
            </svg>
            <p className="text-base text-white">
              Cultivate and advance your business through customer retention
              initiatives.
            </p>
          </div>

          <div className="flex items-center justify-start gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="14"
              viewBox="0 0 18 14"
              fill="none"
            >
              <path
                d="M2 5.70588L7.18519 11L16 2"
                stroke="#F40074"
                strokeWidth="4"
                strokeLinecap="round"
              />
            </svg>
            <p className="text-base text-white">
              Showcasing promotional advertising specialties for your business
              across our social media channels.
            </p>
          </div>
          <div className="flex items-center justify-start gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="14"
              viewBox="0 0 18 14"
              fill="none"
            >
              <path
                d="M2 5.70588L7.18519 11L16 2"
                stroke="#F40074"
                strokeWidth="4"
                strokeLinecap="round"
              />
            </svg>
            <p className="text-base text-white">
              Incentivize customers for repeat business, fostering growth and
              much more ...
            </p>
          </div>
        </div>
        <button className="block my-4 sm:my-2 w-max cursor-pointer relative font-bold duration-300 text-primary-4 text-white px-5 py-3 md:px-7 md:py-4 text-sm md:text-base hover:-translate-y-0.5 bg-primary-4 rounded-md">
          Register
        </button>
      </div>
      <div
        data-aos="fade-left"
        className="w-[60%] md:w-[40%] xl:w-1/2 flex items-center justify-center"
      >
        <img src={img} width={600} height={420} alt={`different-methods`} />
      </div>
    </div>
  );
};
