export const fixedMoney = (money) => {
  return parseFloat(parseFloat(money).toFixed(8));
};
export const fixedNumber = (number, digits) => {
  return Math.trunc(number * Math.pow(10, digits)) / Math.pow(10, digits);
};
export const formatMoney = (money) => {
  let value = "";
  let dotIndex = money.indexOf(".");
  let length = money.length;

  if (dotIndex !== -1) length = dotIndex;

  for (let index = 1; index <= length; index++) {
    if (index % 3 === 1 && index > 1)
      value = `${money[length - index]},` + value;
    else value = money[length - index] + value;
  }
  if (dotIndex !== -1) value = value + money.slice(dotIndex);

  return value;
};

export const formatMoneyDot = (money) => {
  let value = "";
  let dotIndex = money.indexOf(".");
  let length = money.length;

  if (dotIndex !== -1) length = dotIndex;

  for (let index = 1; index <= length; index++) {
    if (index % 3 === 1 && index > 1)
      value = `${money[length - index]},` + value;
    else value = money[length - index] + value;
  }
  if (dotIndex !== -1) value = value + money.slice(dotIndex);

  return value;
};

export const formatDateTime = (str, char) => {
  let strSplit = str.split(char);

  let value1 = `${Number(strSplit[0]) < 10 ? `0${strSplit[0]}` : strSplit[0]}`;
  let value2 = `${
    Number(strSplit[1]) < 10
      ? `${char}0${strSplit[1]}`
      : `${char}${strSplit[1]}`
  }`;
  let value3 = `${
    Number(strSplit[2]) < 10
      ? `${char}0${strSplit[2]}`
      : `${char}${strSplit[2]}`
  }`;

  return value1 + value2 + value3;
};

export const covertWallet = (str) => {
  var strStart = str?.slice(0, 4);
  var strEnd = str?.slice(str?.length - 3, str?.length);
  return `${strStart}...${strEnd}`;
};

export function formatMoneyUS(amount) {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });

  return formatter.format(amount);
}
