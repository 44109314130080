import {
  Section,
  Content,
  Item,
  ContainerStyled,
  Text,
  TextItem,
  BgNft,
  StyledButton,
  Image,
  ItemFlex,
  Overlay,
} from "./styles";
import { CircularProgress, Typography } from "@mui/material";
import BgNftClassic from "assets/NFT/bg-nft-classic.svg";
import Vector from "assets/img/Vector.svg";
import loadingG from "assets/img/loadingg.gif";
import NftItem1 from "assets/svg/NftItem.svg";
import bg from "assets/svg/bg-NftItem.svg";
import BgNftGold from "assets/svg/bg-nft-gold.svg";
import BgNftPlatinum from "assets/svg/bg-nft-platinum.svg";
import BgNftSilver from "assets/svg/bg-nft-silver.svg";
import BgNftVip from "assets/svg/bg-nft-vip.svg";
import QR from "assets/svg/qrcode.svg";
import EmptyData from "components/EmptyData";
import { fixedNumber, formatMoneyDot } from "components/FormatNumber";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleGetMyNftCard } from "redux/slices/nft";

const Card = () => {
  const dispatch = useDispatch();
  const { listMyNftCard, loadding } = useSelector((state) => state.nft);
  const { getPriceNow } = useSelector((state) => state.wallet);

  useEffect(() => {
    dispatch(handleGetMyNftCard());
  }, []);
  const submit = (id) => {};
  return (
    <>
      <Section>
        <ContainerStyled>
          {!listMyNftCard ? (
            <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
              <img src={loadingG} alt="" style={{ width: "15%" }} />
            </div>
          ) : (
            <>
              <Content>
                {listMyNftCard?.total === 0 && <EmptyData />}
                {listMyNftCard?.data?.map((e, i) => (
                  <Item data-aos="flip-left" data-aos-offset="30" key={i}>
                    <Text>
                      <TextItem>
                        {e?.name}
                        <br></br>
                        {formatMoneyDot(String(fixedNumber(e?.price, 2)))}{" "}
                        <span style={{ fontWeight: "700", fontSize: "14px" }}>
                          NOW
                        </span>
                      </TextItem>
                      <div>
                        <div className="flex items-center justify-start gap-1">
                          <img src={Vector} alt="" /> <span>24 months</span>
                        </div>
                        <div className="flex items-center justify-start gap-1">
                          <img src={Vector} alt="" />{" "}
                          <span>{e?.percent}% / monthly</span>
                        </div>
                      </div>
                    </Text>
                    <ItemFlex>
                      <Image
                        style={{
                          width: "87px",
                          textAlign: "center",
                          padding: "10px 0",
                        }}
                      >
                        <img
                          src={NftItem1}
                          alt=""
                          style={{ maxHeight: "220px" }}
                        />

                        {/* <img src={e?.img} alt="" style={{ maxHeight: "220px" }} /> */}
                      </Image>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          gap: "20px",
                        }}
                      >
                        <span>{e?.created_at.substring(0, 10)}</span>
                      </div>
                    </ItemFlex>

                    {e.name === "Classic" && (
                      <>
                        <Overlay bg={"#9B0A5C"}></Overlay>
                        <BgNft>
                          <img src={BgNftClassic} alt="" />
                        </BgNft>
                      </>
                    )}
                    {e.name === "Silver" && (
                      <>
                        <Overlay
                          bg={
                            "linear-gradient(268.17deg, #D3D4D4 6.81%, #536981 99.24%)"
                          }
                        ></Overlay>
                        <BgNft>
                          <img src={BgNftSilver} alt="" />
                        </BgNft>
                      </>
                    )}
                    {e.name === "Gold" && (
                      <>
                        <Overlay
                          bg={
                            "linear-gradient(90.67deg, #FEC874 3.8%, #F5D087 95.98%)"
                          }
                        ></Overlay>
                        <BgNft>
                          <img src={BgNftGold} alt="" />
                        </BgNft>
                      </>
                    )}
                    {e.name === "Platinum" && (
                      <>
                        <Overlay
                          bg={
                            "linear-gradient(269.77deg, #FEBDA3 0.26%, #E39B9D 89.14%)"
                          }
                        ></Overlay>
                        <BgNft>
                          <img src={BgNftPlatinum} alt="" />
                        </BgNft>
                      </>
                    )}
                    {e.name === "VIP NOW" && (
                      <>
                        <Overlay
                          bg={
                            "linear-gradient(88.34deg, #1A2A43 5.64%, #130E25 99.53%)"
                          }
                        ></Overlay>
                        <BgNft>
                          <img src={BgNftVip} alt="" />
                        </BgNft>
                      </>
                    )}
                  </Item>
                ))}
              </Content>
              <img src={bg} alt="" />
            </>
          )}
        </ContainerStyled>
      </Section>
    </>
  );
};
export default Card;
