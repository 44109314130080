import { NFTLocationStyles, Pagi, Wrapper } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Box, CircularProgress, Pagination, Typography } from "@mui/material";
import EmptyData from "components/EmptyData";
import { fixedNumber, formatMoneyDot } from "components/FormatNumber";
import { BuyNFTMarketplace } from "components/Message";
import _ from "lodash";
import { useState } from "react";
import { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleListMarketPlace } from "redux/slices/marketplace";
import { CheckImage, getColor, GetUser } from "utits/auth";

const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      padding: "10px",
    },
    root: {
      "& .Mui-selected": {
        background: "#a100a5!important",
        color: "white",
      },
      "& ul > li > button": {
        backgroundColor: "white",
        color: "black",
      },
    },
  })
);
const NFTLocations = () => {
  const user = GetUser();
  const [page, setPage] = useState(1);
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { listMarketPlace } = useSelector((state) => state.marketplace);
  const { loaddingSell } = useSelector((state) => state.nft);
  const { getPriceNow } = useSelector((state) => state.wallet);

  useEffect(() => {
    const data = {
      page: page,
      keywords: "",
      locationid: "",
      cateloryid: "",
    };
    dispatch(handleListMarketPlace(data));
  }, [page]);

  const handleBuyNFT = (locationid) => {
    if (user) {
      BuyNFTMarketplace(dispatch, locationid);
    } else {
      navigate("/auth/login");
    }
  };
  return (
    <>
      <NFTLocationStyles.Main>
        <NFTLocationStyles.Container className="container">
          {listMarketPlace?.total === 0 && <EmptyData />}
          {listMarketPlace?.data?.map((e, i) => (
            <Wrapper.main>
              <Wrapper.title>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    color: "#fff",
                  }}
                >
                  <Typography
                    variant="button"
                    sx={{ fontSize: "16px !important" }}
                  >
                    {formatMoneyDot(String(fixedNumber(e?.price, 2)))}{" "}
                    <span style={{ fontWeight: "700", fontSize: "14px" }}>
                      NOW
                    </span>
                  </Typography>
                </Box>
                <div>
                  <p>{e?.category}</p>
                  <Wrapper.Ratting>
                    {_.times(5, (i) => (
                      <FontAwesomeIcon
                        icon="star"
                        style={{ color: getColor(e?.rating, i) }}
                      />
                    ))}
                  </Wrapper.Ratting>
                </div>
              </Wrapper.title>

              <Wrapper.Image>
                <LazyLoadImage
                  style={{
                    height: "264px",
                    objectFit: "cover",
                    width: "100%",
                  }}
                  alt={"img"}
                  effect="blur"
                  src={CheckImage(e?.images[0])}
                />
              </Wrapper.Image>
              <Wrapper.content>
                <h3>{e.name}</h3>
                <span>{e.address}</span>
              </Wrapper.content>
              <Wrapper.Bottom>
                <Wrapper.Detail
                  onClick={() => navigate(`/maps/${e?.locationid}`)}
                >
                  Detail
                </Wrapper.Detail>
                <Wrapper.Buy
                  onClick={() => {
                    handleBuyNFT(e?.locationid);
                  }}
                >
                  {loaddingSell === e?.locationid ? (
                    <CircularProgress
                      style={{
                        width: "15px",
                        height: "15px",
                        color: "white",
                        margin: "auto",
                      }}
                    />
                  ) : (
                    " Buy Now"
                  )}
                </Wrapper.Buy>
              </Wrapper.Bottom>
            </Wrapper.main>
          ))}
        </NFTLocationStyles.Container>
      </NFTLocationStyles.Main>
      {listMarketPlace?.total > 12 && (
        <Pagi>
          <Box className={classes.box}>
            <Pagination
              count={
                listMarketPlace?.total
                  ? Math.ceil(listMarketPlace?.total / 12)
                  : 0
              }
              size="large"
              onChange={(e, p) => setPage(p)}
              className={classes.root}
            />
          </Box>
        </Pagi>
      )}
    </>
  );
};
export default NFTLocations;
