import ArrowRightIcon from "icons/Arrow/ArrowRight";

export const GetADemo = () => {
  return (
    <div className="bg-gradient-get-a-demo">
      <div className="pb-10 md:pb-16 container flex items-center gap-8 flex-col">
        <p className="animate-typing overflow-hidden whitespace-nowrap border-r-4 border-r-white pr-5 text-white md:text-7xl text-center text-4xl font-extrabold container sm:max-w-none md:max-w-none lg:container mx-auto">
          Join us to develop your career name.
        </p>

        <button className="flex items-center justify-start gap-2 my-4 sm:my-0 min-w-max cursor-pointer relative font-bold duration-300 text-primary-4 text-white px-4 py-2 md:px-6 md:py-3 text-sm xl:text-base hover:-translate-y-0.5 bg-primary-4 rounded-md">
          Get a Demo
          <span className="w-6">
            <ArrowRightIcon />
          </span>
        </button>
      </div>
    </div>
  );
};
