import FacebookIcon from "icons/Community/Facebook";
import InsIcon from "icons/Community/Ins";
import RedditIcon from "icons/Community/Reddit";
import TelegramIcon from "icons/Community/Telegram";
import TwitterIcon from "icons/Community/Twitter";
import YoutubeIcon from "icons/Community/Youtube";

export const navFooter = [
  {
    title: "Company",
    child: [
      {
        name: "About us",
        link: "#",
      },
      {
        name: "Careers",
        link: "#",
      },
      {
        name: "Contact us",
        link: "#",
      },
    ],
  },
  {
    title: "Information",
    child: [
      {
        name: "Terms and Privacy Policy",
        link: "/Terms-of-Service.pdf",
      },
      {
        name: "Cookies Policy",
        link: "/policy",
      },
      {
        name: "Account Deletion",
        link: "/account-clean-up",
      },
      {
        name: "Account Cleanup",
        link: "/account-clean-up",
      },
    ],
  },
];
export const communities = [
  {
    icon: <FacebookIcon color="text-black duration-300" />,
  },
  {
    icon: <InsIcon color="text-black duration-300" />,
  },
  {
    icon: <RedditIcon color="text-black duration-300" />,
  },
  {
    icon: <TelegramIcon color="text-black duration-300" />,
  },
  {
    icon: <TwitterIcon color="text-black duration-300" />,
  },
  {
    icon: <YoutubeIcon color="text-black duration-300" />,
  },
];
