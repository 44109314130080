import img1 from "assets/images/home/helping1.png";
import img2 from "assets/images/home/helping2.png";
import img3 from "assets/images/home/helping2.png";

export const Helping = () => {
  return (
    <div className="bg-gradient-helping">
      <div className="my-10 md:my-16 py-10 md:py-16 container overflow-hidden flex flex-col md:flex-row items-center justify-around gap-3 md:gap-5 lg:gap-8">
        <div
          data-aos="fade-right"
          className="flex w-full flex-col items-start justify-center gap-3"
        >
          <p className="md:text-4xl 2xl:text-5xl text-left text-white text-3xl font-extrabold">
            Helping a local
          </p>
          <p className="md:text-4xl 2xl:text-5xl text-left text-3xl font-extrabold text-primary-4">
            business reinvent itself
          </p>
          <p className="text-xl text-left w-full mx-auto md:text-xl text-white">
            We reached here with our hard work and dedication
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 w-full py-4 gap-10 md:gap-12 lg:gap-16">
          <div
            className="flex flex-row gap-5 items-center justify-start"
            data-aos="fade-up"
          >
            <img src={img1} width={60} height={60} alt={`Helping 1`} />
            <div className="flex flex-col gap-1.5 items-start justify-start">
              <p className="text-white md:text-2xl lg:text-4xl text-left text-2xl font-bold">
                2,245,341
              </p>
              <p className="text-white md:text-sm lg:text-xl text-left text-sm font-medium">
                Members
              </p>
            </div>
          </div>
          <div
            className="flex flex-row gap-5 items-center justify-start"
            data-aos="fade-up"
          >
            <img src={img2} width={60} height={60} alt={`Helping 2`} />
            <div className="flex flex-col gap-1.5 items-start justify-start">
              <p className="text-white md:text-2xl lg:text-4xl text-left text-2xl font-bold">
                46,328
              </p>
              <p className="text-white md:text-sm lg:text-xl text-left text-sm font-medium">
                Clubs
              </p>
            </div>
          </div>
          <div
            className="flex flex-row gap-5 items-center justify-start"
            data-aos="fade-up"
          >
            <img src={img3} width={60} height={60} alt={`Helping 3`} />
            <div className="flex flex-col gap-1.5 items-start justify-start">
              <p className="text-white md:text-2xl lg:text-4xl text-left text-2xl font-bold">
                828,867
              </p>
              <p className="text-white md:text-sm lg:text-xl text-left text-sm font-medium">
                Event Bookings
              </p>
            </div>
          </div>
          <div
            className="flex flex-row gap-5 items-center justify-start"
            data-aos="fade-up"
          >
            <img src={img1} width={60} height={60} alt={`Helping 4`} />
            <div className="flex flex-col gap-1.5 items-start justify-start">
              <p className="text-white md:text-2xl lg:text-4xl text-left text-2xl font-bold">
                1,926,436
              </p>
              <p className="text-white md:text-sm lg:text-xl text-left text-sm font-medium">
                Payments
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
