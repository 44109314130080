import Footer from "./HomeMain/Footers";
import Header from "./HomeMain/header";
import bg from "assets/svg/bg-Trusted.svg";

export default function ContactSection() {
  return (
    <div className=" relative">
      <Header />
      <div className="  overflow-hidden  container my-20 md:my-3">
        <div className="container mx-auto flex flex-col items-center w-full justify-center pt-32 md:pt-36 pb-0 lg:pb-20">
          <p className="text-primary-0 mt-4 md:mt-0 3xl:text-[60px] 2xl:text-[50px] lg:text-[48px] md:text-3xl text-3xl font-bold text-center bg-gradient-to-r bg-clip-text from-[#F40074] via-[#921CF5] to-[#F40074] text-transparent lg:pb-6 md:pb-5 sm:pb-4 pb-3.5">
            Contact us
          </p>
          <div className="max-w-xs mx-auto bg-gradient-button-purple h-1 w-full my-7 rounded-md"></div>
          <p className="text-base xl:text-lg text-[#DAEBF1] font-normal max-w-lg text-center"></p>
        </div>
        <div className=" pt-5 md:pt-10 lg:pt-16 overflow-hidden pb-40 md:pb-56 lg:grid lg:grid-cols-2 lg:grid-rows-1 flex flex-col container mx-auto max-w-8xl">
          <div className="flex flex-col items-start">
            <p className="mt-5 md:mt-8 text-3xl lg:text-4xl xl:text-5xl font-bold text-white">
              Get In Touch
            </p>
            <p className="mt-5 md:mt-8 text-base xl:text-lg text-[#DAEBF1] font-normal text-left"></p>
            <div>
              <p className="text-lg lg:text-xl xl:text-2xl font-bold text-white">
                Email Us
              </p>
              <p className="text-base xl:text-lg text-white font-normal">
                info@nowmarketing.net
              </p>
            </div>
          </div>

          <div className="relative mt-10 lg:mt-0 z-20">
            <form>
              <div className="flex flex-col items-start gap-3">
                <input
                  className="p-3 md:p-5 rounded-lg  w-full h-full focus:outline-none text-black border border-[#F400A0]"
                  type="text"
                  placeholder="Subject"
                />
                <textarea
                  className="p-3  rounded-lg  md:p-5 overflow-hidden resize-none w-full focus:outline-none block text-black border border-[#F400A0]"
                  name="message"
                  placeholder="Message"
                />
              </div>

              <button className="px-6 !rounded-full z-10 py-1.5 mt-5 md:px-8 xl:px-10 xl:py-1  duration-300 hover:scale-105 text-white bg-gradient-button text-xl lg:text-2xl xl:text-2xl font-bold">
                Send
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />

      <img
        src={bg}
        alt=""
        style={{
          position: "absolute",
          top: "0%",
          left: "5%",
          width: "80%",
          overflow: "hidden",
          zIndex: "1",
        }}
      />
    </div>
  );
}
