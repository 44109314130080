import security from "assets/images/home/security.png";

export const Security = () => {
  return (
    <div className="py-10 md:py-16 container flex flex-col md:flex-row items-center gap-3 md:gap-8">
      <div
        data-aos="fade-right"
        className="w-[60%] md:w-[40%] xl:w-1/2 flex items-center justify-center mb-4 sm:mb-0"
      >
        <img src={security} width={600} height={420} alt="Security Image" />
      </div>
      <div
        data-aos="fade-left"
        className="flex flex-col items-start justify-start gap-3 md:gap-8 w-full md:w-1/2"
      >
        <p className=" text-white md:text-xl text-left text-base font-extrabold">
          ABOUT US
        </p>
        <p className=" text-white md:text-4xl text-left text-2xl font-extrabold">
          Revolutionize Your Brand: Discover the Uniqueness of Our Cutting-Edge
          Marketing Strategies
        </p>
        <div className=" text-white flex flex-col gap-3">
          <p className=" text-white text-base">
            We're all about the magic of creative concepts. Think of it as the
            heartbeat of what we do—a brilliant approach that grabs your
            audience's attention, stirs up their emotions, and gets them
            motivated to do something awesome. It's not just a fancy idea; it's
            the secret sauce that makes things click. We get that at NOW
            marketing, and we're here to sprinkle that creative magic into
            everything we do.
          </p>
        </div>
        <button className="block my-4 sm:my-2 w-max cursor-pointer relative font-bold duration-300 text-white px-5 py-3 md:px-7 md:py-4 text-sm md:text-base hover:-translate-y-0.5 bg-primary-4 rounded-md">
          Learn More
        </button>
      </div>
    </div>
  );
};
