const RedditIcon = (props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${props.color}`}
    >
      <path
        d="M16.1544 32.0003C24.8188 32.0003 31.8427 24.9864 31.8427 16.3344C31.8427 7.68232 24.8188 0.668457 16.1544 0.668457C7.48993 0.668457 0.466019 7.68232 0.466019 16.3344C0.466019 24.9864 7.48993 32.0003 16.1544 32.0003Z"
        fill="#FFF"
      />
      <path
        d="M26.6096 16.3346C26.6096 15.0732 25.5871 14.0521 24.3239 14.0521C23.706 14.0521 23.1482 14.2978 22.7381 14.691C21.1742 13.5661 19.0197 12.8344 16.6192 12.7525L17.6636 7.85455L21.0703 8.57532C21.1141 9.43806 21.8195 10.1315 22.6944 10.1315C23.5966 10.1315 24.3294 9.39984 24.3294 8.49887C24.3294 7.59791 23.5966 6.86621 22.6944 6.86621C22.0546 6.86621 21.5023 7.23752 21.2344 7.7781L17.4339 6.96996C17.33 6.94812 17.2152 6.96996 17.1277 7.02456C17.0348 7.08463 16.9746 7.17746 16.9527 7.2812L15.788 12.7416C13.3546 12.8071 11.1619 13.5388 9.58156 14.6746C9.17145 14.2814 8.61369 14.0357 8.00125 14.0357C6.73809 14.0357 5.71553 15.0568 5.71553 16.3182C5.71553 17.2464 6.27329 18.0437 7.06618 18.3986C7.03337 18.6279 7.0115 18.8573 7.0115 19.0921C7.0115 22.6086 11.1072 25.4534 16.1598 25.4534C21.2125 25.4534 25.3082 22.6031 25.3082 19.0921C25.3082 18.8627 25.2918 18.6334 25.2535 18.404C26.0519 18.0655 26.6096 17.2628 26.6096 16.3346ZM10.9268 17.9672C10.9268 17.0662 11.6595 16.3346 12.5617 16.3346C13.464 16.3346 14.1968 17.0662 14.1968 17.9672C14.1968 18.8682 13.464 19.5999 12.5617 19.5999C11.6595 19.5944 10.9268 18.8627 10.9268 17.9672ZM20.0368 22.2755C18.9213 23.3894 16.7832 23.4768 16.1544 23.4768C15.5255 23.4768 13.3875 23.3894 12.2719 22.2755C12.1079 22.1117 12.1079 21.8441 12.2719 21.6748C12.436 21.511 12.7039 21.511 12.8734 21.6748C13.5788 22.3792 15.0826 22.6249 16.1544 22.6249C17.2262 22.6249 18.7299 22.3738 19.4353 21.6748C19.5994 21.511 19.8673 21.511 20.0368 21.6748C20.2009 21.8441 20.2009 22.1117 20.0368 22.2755ZM19.747 19.5944C18.8448 19.5944 18.112 18.8627 18.112 17.9618C18.112 17.0608 18.8448 16.3291 19.747 16.3291C20.6493 16.3291 21.382 17.0608 21.382 17.9618C21.382 18.8627 20.6493 19.5944 19.747 19.5944Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default RedditIcon;
