import { Wrapper } from "./styles";
import number from "assets/img/4.png";
import globe from "assets/img/globe.png";
import Footer from "pages/HomeMain/Footers";
import Header from "pages/HomeMain/header";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <Wrapper.Main>
        <div className="container">
          <Wrapper.Container>
            <Wrapper.Globe>
              <img src={number} alt="" />
              <img src={globe} alt="" className="globe" />
              <img src={number} alt="" />
            </Wrapper.Globe>
          </Wrapper.Container>
          <Wrapper.Text>
            <h1>We have a problem!</h1>
            <p>This page doesn't exist. You can go back to previous page.</p>
            <button onClick={() => navigate("/")}>GO BACK</button>
          </Wrapper.Text>
        </div>
      </Wrapper.Main>
      {/* <Footer /> */}
    </>
  );
};
export default NotFoundPage;
