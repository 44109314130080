import BannerCountDown from "./BannerCountDown";
import Faq from "./Faq";
import Footer from "./Footers";
import Newspapers from "./Newspapers";
import NftItem from "./NftItem";
import Trusted from "./Trusted";
import Allocation from "./allocation";
import Banner from "./banner";
import Header from "./header";
import HomeContainer from "./home";
import Roadmap from "./roadmap";
import bg from "assets/svg/bg-Trusted.svg";
import React from "react";

export default function HomeMain() {
  return (
    <div>
      <Header />
      {/* <Banner />
      <Newspapers />
      <Trusted />
      <Allocation />
      <Roadmap />
      <BannerCountDown />
      <Faq /> */}
      <HomeContainer />
      <Footer />

      {/* <NftItem /> */}
      <img
        src={bg}
        alt=""
        style={{
          position: "absolute",
          top: "50%",
          left: "-25%",
          width: "60%",
          overflow: "hidden",
          zIndex: "1",
        }}
      />
    </div>
  );
}
