import img from "assets/images/home/banner1.png";
import bg from "assets/images/home/banner-bg.png";

export const BANNER_DUMMY = [
  {
    img: img,
    bg: bg,
    title: "Marketing is The Best Way to Grow Your Business",
    des: "",
    button: "GET STARTED",
  },
  {
    bg: bg,
    img: img,
    title: "Marketing 1 is The Best Way to Grow Your Business",
    button: "GET STARTED",
    des: "",
  },
  {
    bg: bg,
    img: img,
    title: "Marketing 2 is The Best Way to Grow Your Business",
    button: "GET STARTED",
    des: "",
  },
];
