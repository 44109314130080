import Route from "./routes";
import fontawesome from "@fortawesome/fontawesome";
import { faStar } from "@fortawesome/fontawesome-free-solid";
import { useLoadScript } from "@react-google-maps/api";
import Aos from "aos";
import "aos/dist/aos.css";
import "common/styles/index.css";
import "common/styles/index.scss";
import "nprogress/nprogress.css";
import { useEffect } from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { store } from "redux/stores";

fontawesome.library.add(faStar);

const App = () => {
  useEffect(() => {
    Aos.init({ duration: 800, delay: 350 });
    Aos.refresh();
  }, []);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY.substring(
      process.env.REACT_APP_GOOGLE_MAP_KEY.length - 5,
      5
    ),
  });
  return (
    <Provider store={store}>
      <Route />
    </Provider>
  );
};

export default App;
