import { BANNER_DUMMY } from "./helper";
import ArrowLeftIcon from "icons/Arrow/ArrowLeft";
import ArrowRightIcon from "icons/Arrow/ArrowRight";
import { useEffect, useState } from "react";

const Banner = () => {
  const [windowWidth, setWindowWidth] = useState(0);
  const handleResize = () => setWindowWidth(window.innerWidth);
  useEffect(() => {
    const init = async () => {
      const { Carousel, initTE } = await import("tw-elements");
      initTE({ Carousel }, { allowReinits: true });
    };
    init();
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div
      className="relative mt-[70px]"
      id="carouselDarkVariant"
      data-te-carousel-init
      data-te-ride="carousel"
    >
      <div
        className="absolute inset-x-0 bottom-0 z-[2] mx-[15%] mb-2 md:mb-3 2xl:mb-5 flex list-none justify-center p-0"
        data-te-carousel-indicators
      >
        <button
          className="mx-[3px] rounded-full box-content w-4 h-4 md:h-6 md:w-6 flex-initial cursor-pointer border-transparent hover:opacity-100 bg-gradient-button-purple p-0 -indent-[999px] opacity-50 transition-opacity duration-[500ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
          data-te-target="#carouselDarkVariant"
          data-te-slide-to="0"
          data-te-carousel-active
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          className="mx-[3px] rounded-full box-content w-4 h-4 md:h-6 md:w-6 flex-initial cursor-pointer border-transparent hover:opacity-100 bg-gradient-button-purple p-0 -indent-[999px] opacity-50 transition-opacity duration-[500ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
          data-te-target="#carouselDarkVariant"
          data-te-slide-to="1"
          data-te-carousel-active
          aria-label="Slide 2"
        ></button>
        <button
          className="mx-[3px] rounded-full box-content w-4 h-4 md:h-6 md:w-6 flex-initial cursor-pointer border-transparent hover:opacity-100 bg-gradient-button-purple p-0 -indent-[999px] opacity-50 transition-opacity duration-[500ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
          data-te-target="#carouselDarkVariant"
          data-te-slide-to="2"
          aria-label="Slide 3"
        ></button>
      </div>

      <div className="banner-home relative w-full overflow-hidden after:clear-both after:block after:content-['']">
        {BANNER_DUMMY.map((banner, index) => (
          <div
            key={index}
            data-te-carousel-fade
            data-te-carousel-item
            data-te-carousel-active={index === 0 ? true : undefined}
            className={
              index === 0
                ? "relative float-left -mr-[100%] w-full !transform-none transition-opacity duration-[500ms] ease-in-out motion-reduce:transition-none"
                : "hidden relative float-left -mr-[100%] w-full !transform-none transition-opacity duration-[500ms] ease-in-out motion-reduce:transition-none"
            }
          >
            <div className="absolute w-full h-full  flex items-end sm:items-center justify-start pb-9 sm:pb-0">
              <div className="sm:container w-full flex flex-col sm:flex-row items-center justify-between">
                <div className="flex flex-col gap-1 md:gap-2 lg:gap-3 2xl:gap-5 items-center sm:items-start justify-center sm:justify-start w-full sm:w-1/2">
                  <p className="text-center text-3xl sm:text-left sm:text-2xl lg:text-3xl xl:text-5xl 2xl:text-6xl !leading-tight 2xl:leading-none font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary-7 via-primary-8 to-primary-4">
                    {banner?.title}
                  </p>
                  <p className="text-center sm:text-left text-sm md:text-base lg:text-lg pb-1 xl:pb-3 2xl:pb-0 xl:text-xl text-white font-medium">
                    {banner?.des}
                  </p>
                  <a href={"/single-product"} passHref>
                    <button className="block my-4 sm:my-0 w-max cursor-pointer relative font-bold duration-300 text-white px-4 py-2 md:px-7 md:py-4 text-xs md:text-base hover:-translate-y-0.5 bg-primary-4 rounded-md">
                      {" "}
                      {banner?.button}
                    </button>
                  </a>
                </div>
                <div className="w-1/2 sm:w-[40%] md:w-[30%] flex items-center justify-center">
                  <img
                    width={500}
                    height={650}
                    src={banner?.img}
                    alt={`Banner ${index}`}
                  />
                </div>
              </div>
            </div>
            <img
              width={2680}
              className="-z-10 min-h-[450px] sm:min-h-[300px]"
              height={1150}
              src={banner?.bg}
              alt={`Banner ${index}`}
            />
          </div>
        ))}
      </div>

      <button
        className="absolute bottom-0 border-transparent left-2 top-0 z-[1] w-[10%] flex items-center justify-center border-0 bg-none p-0 text-center text-black transition-all duration-300"
        type="button"
        data-te-target="#carouselDarkVariant"
        data-te-slide="prev"
      >
        <span className="h-8 w-8 lg:w-10 lg:h-10 text-white hover:bg-gradient-button bg-gradient-button-purple p-2 rounded-full">
          <ArrowLeftIcon />
        </span>
        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          Previous
        </span>
      </button>
      <button
        className="absolute bottom-0 border-transparent right-2 top-0 z-[1] w-[10%] flex items-center justify-center border-0 bg-none p-0 text-center text-black transition-all duration-300"
        type="button"
        data-te-target="#carouselDarkVariant"
        data-te-slide="next"
      >
        <span className="h-8 w-8 lg:w-10 lg:h-10 text-white hover:bg-gradient-button bg-gradient-button-purple p-2 rounded-full">
          <ArrowRightIcon />
        </span>
        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          Next
        </span>
      </button>
    </div>
  );
};

export default Banner;
