import { auth } from "./Auth";
import { dashboard } from "./Dashboard";
import { maps } from "./Maps";
import { PATH } from "./PATH";
import AcceptCookie from "components/AcceptCookie";
import LoaddingGif from "components/LoaddingGif";
import Auth from "layouts/Auth";
import Dashboard from "layouts/Dashboard";
import ContactSection from "pages/Contact";
import CreateLocation from "pages/CreateLocation";
import HomeMain from "pages/HomeMain";
import Marketplace from "pages/MarketPlace";
import NotFoundPage from "pages/NotFound";
import PrivacyPolicy from "pages/Policy/Components/PrivacyPolicy";
import DataDeletion from "pages/Profile/DataDeletion";
import DeviceConfirm from "pages/auth/DeviceConfirm";
import { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const MapsView = lazy(() => import("pages/MapsView"));
const ViewToEarnApp = lazy(() => import("pages/ViewToEarnApp"));
const pathNotCookie = ["/mapsview", "/app-earning", "/app-earning-card"];
const Router = () => {
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div>
            <LoaddingGif />
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<HomeMain />} />
          <Route path="/contact" element={<ContactSection />} />
          <Route path="/marketplace" element={<Marketplace />} />
          <Route path="/confirm-code-device" element={<DeviceConfirm />} />
          <Route path="/create-location" element={<CreateLocation />} />
          <Route path="/mapsview" element={<MapsView />} />
          <Route path="/app-earning" element={<ViewToEarnApp />} />
          <Route path="/app-earning-card" element={<ViewToEarnApp />} />
          <Route path="/policy" element={<PrivacyPolicy />} />

          <Route path="/" element={<Auth />}>
            {maps.map((route, index) => (
              <Route
                key={index}
                index={index !== 0}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>
          <Route path="/" element={<Dashboard />}>
            <Route
              path={PATH.DASHBOARD.DATA_DELECTION}
              element={<DataDeletion />}
            />
            {dashboard.map((route, index) => (
              <Route
                key={index}
                index={index !== 0}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>

          <Route path="/auth" element={<Auth />}>
            {auth.map((route, index) => (
              <Route
                key={index}
                index={index !== 0}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
      {pathNotCookie.every((path) => window.location.pathname !== path) && (
        <AcceptCookie />
      )}
    </BrowserRouter>
  );
};
export default Router;
