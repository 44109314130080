const TelegramIcon = (props) => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${props.color}`}
    >
      <path
        d="M16.3582 31.3318C25.0227 31.3318 32.0466 24.3179 32.0466 15.6659C32.0466 7.01387 25.0227 0 16.3582 0C7.69382 0 0.669907 7.01387 0.669907 15.6659C0.669907 24.3179 7.69382 31.3318 16.3582 31.3318Z"
        fill="#FFF"
      />
      <path
        d="M13.1921 24.6864C20.8531 24.6864 25.0418 18.3468 25.0418 12.8537C25.0418 12.6735 25.0363 12.4933 25.0309 12.3185C25.8456 11.7288 26.551 10.9971 27.1088 10.1671C26.3596 10.5002 25.5613 10.7186 24.7192 10.8224C25.5777 10.3091 26.2393 9.49005 26.551 8.52356C25.7472 8.99861 24.8559 9.34808 23.9044 9.53373C23.1443 8.72559 22.0616 8.21777 20.8641 8.21777C18.5619 8.21777 16.6973 10.0798 16.6973 12.3786C16.6973 12.7062 16.7356 13.0229 16.8066 13.3287C13.3453 13.154 10.2721 11.4995 8.22152 8.98223C7.86608 9.59926 7.65829 10.3091 7.65829 11.0736C7.65829 12.5151 8.39103 13.7928 9.51202 14.5355C8.82849 14.5136 8.18871 14.328 7.62548 14.0167C7.62548 14.0331 7.62548 14.0495 7.62548 14.0713C7.62548 16.0862 9.06363 17.768 10.9666 18.1503C10.6166 18.2431 10.2502 18.2977 9.86746 18.2977C9.59951 18.2977 9.33704 18.2704 9.0855 18.2212C9.61592 19.8757 11.1525 21.077 12.9789 21.1098C11.5517 22.2237 9.75809 22.8899 7.80593 22.8899C7.47237 22.8899 7.13881 22.868 6.81071 22.8298C8.64804 23.9983 10.8353 24.6864 13.1921 24.6864Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default TelegramIcon;
